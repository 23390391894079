/* @font-face {
  src: local('Adventure'), url("./assets/fonts/Adventure.ttf") format("truetype");
  font-family: "Adventure";
} */

@font-face {
  font-family: "Orkney";
  src: local("Orkney"), url("./assets/fonts/orkney/Orkney\ Regular.eot");
  src: local("Orkney"), url("./assets/fonts/orkney/Orkney\ Regular.ttf") format("truetype");
  src: local("Orkney") url("./assets/fonts/orkney/Orkney\ Regular.woff") format("woff"),
    local("Orkney") url("./assets/fonts/orkney/Orkney\ Regular.otf") format("opentype");
}

.grecaptcha-badge {
  z-index: 3;
}

body {
  margin: 0;
  font-family: "Orkney", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  /* box-shadow: inset 0 0 6px #456673; */
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  padding: 0 5px;
  background-color: #456673;
}